import { ReactChakraSelect } from '@springcare/sh-component-library';
import keyBy from 'lodash/keyBy';
import { useTracking } from 'modules/shared/hooks/useTracking';

export const Select = ({ options, value, onChange, ...rest }) => {
  const optionsDictionary = keyBy(options, 'value');

  return (
    <ReactChakraSelect
      value={value ? optionsDictionary[value] : undefined}
      onChange={(option) => {
        onChange(option?.value);
      }}
      options={options}
      {...rest}
      isMulti={false}
      chakraStyles={{
        menu: (provided) => ({
          ...provided,
          overflow: 'visible',
        }),
      }}
    />
  );
};
