import React, { useEffect, useState } from 'react';
import { Box, Button, Code, Container, Divider, Flex, Input, Link, Text } from '@springcare/sh-component-library';
import { useRouter } from 'next/router';

import { API_DOMAIN, ATLAS_API_DOMAIN, AUTH_API_DOMAIN, EHR_URL } from 'constants/api';
import { Card } from 'modules/shared/components/Card';
import { Select } from 'modules/shared/components/Select';
import routes from 'routes';
import { PublicLayout } from '../Public.layout';

const IS_PRODUCTION_ENV = ['production', 'internal', 'demo'].includes(process.env.APP_ENV);

const ENVIRONMENTS = {
  staging: 'staging',
  localhost: 'localhost',
  perfLab: 'perf-lab',
  codespaces: 'codespaces',
  dev: 'dev',
};

const setEnv = (rotom, diglet, ehr, atlas) => {
  localStorage.setItem('rotom', rotom);
  localStorage.setItem('diglet', diglet);
  localStorage.setItem('ehr', ehr);
  localStorage.setItem('atlas', atlas);

  window.location = window.location.href.split('?')[0];
};

const resetEnv = () => {
  localStorage.removeItem('rotom');
  localStorage.removeItem('diglet');
  localStorage.removeItem('ehr');
  localStorage.removeItem('atlas');

  window.location.reload(false);
};

const Index = () => {
  const [quickSwapEnv, setQuickSwapEnv] = useState('');
  const [manualSwapEnv, setManualSwapEnv] = useState({
    rotom: API_DOMAIN,
    auth: AUTH_API_DOMAIN,
    ehr: EHR_URL,
    atlas: ATLAS_API_DOMAIN,
  });

  const router = useRouter();

  const localhostRotom = 'http://localhost:4400';
  const localhostDiglet = 'http://localhost:8081';
  const localhostEHR = 'http://localhost:5500';
  const localhostAtlas = 'http://localhost:4000';
  const perfLabRotom = 'https://app-40260.on-aptible.com';
  const perfLabDiglet = 'https://app-40265.on-aptible.com';
  const perfLabEHR = 'https://app-47558.on-aptible.com';
  const perfLabAtlas = ''; //  Not yet deployed

  const codespacesRotom = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-4400.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesDiglet = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-8081.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesEhr = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-5500.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesAtlas = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-4401.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';

  /**
   * NOTE: See https://humanwhocodes.com/blog/2010/01/12/history-of-the-user-agent-string/ for why
   * we can't just check if the UA includes 'Safari'
   */
  const isLocalhostDisabled = !navigator.userAgent.includes('Firefox') && !navigator.userAgent.includes('Chrome');

  useEffect(() => {
    if (IS_PRODUCTION_ENV) {
      router.replace(routes.SignIn.to, routes.SignIn.as);
    }
  }, []);

  const onSubmitQuickSwap = (_e) => {
    switch (quickSwapEnv) {
      case ENVIRONMENTS.localhost:
        return setEnv(localhostRotom, localhostDiglet, localhostEHR, localhostAtlas);
      case ENVIRONMENTS.perfLab:
        return setEnv(perfLabRotom, perfLabDiglet, perfLabEHR, perfLabAtlas);
      case ENVIRONMENTS.codespaces:
        return setEnv(codespacesRotom, codespacesDiglet, codespacesEhr, codespacesAtlas);
      default:
        return setEnv(
          `https://care-api.${quickSwapEnv}.springtest.us`,
          `https://auth-api.${quickSwapEnv}.springtest.us`,
          `https://ehr-api.${quickSwapEnv}.springtest.us`,
          `https://atlas.${quickSwapEnv}.springtest.us`
        );
    }
  };

  const handleOnChangeManualEnv = (e) => {
    setManualSwapEnv((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmitManualSwap = (_e) => {
    const { rotom, auth, ehr, atlas } = manualSwapEnv;
    setEnv(rotom, auth, ehr, atlas);
  };

  if (IS_PRODUCTION_ENV) {
    return null;
  }

  return (
    <>
      {/*Navigate to /logout to clear all the cookies and local storage first before re-signing in*/}
      <Container maxW="container.md">
        <Link href={'/logout'}>Sign In</Link>
        <Text textStyle="heading-display">Magic ENV Toggle</Text>
        <Text textStyle="heading-small" color="warning-base">
          This page will only show up on non-production environments
        </Text>

        <Divider color="content" my={8} w="full" />

        <Text>These forms can help you target any api. By default, Admin Portal will use {process.env.APP_API}.</Text>

        <Text>
          Unfortunately, <Code color="neutral-base">localhost</Code> does not work in Safari, as it does not
          support&nbsp;
          <Link
            href="https://developer.mozilla.org/en-US/docs/Web/Security/Mixed_content#loading_locally_delivered_mixed-resources"
            isExternal
          >
            locally-delivered mixed-content.
          </Link>
        </Text>

        <Box mt={8}>
          <Card>
            <Text textStyle="heading-small">Quick Swap</Text>
            <Text textStyle="body-small">Note: Staging is deprecated, you probably want AWS Development</Text>
            <Flex direction="column" mt={4}>
              <Select
                label="Select an ENV"
                fieldKey="env"
                value={quickSwapEnv}
                options={[
                  { label: 'AWS Development', value: ENVIRONMENTS.dev },
                  { label: 'Codespaces', value: ENVIRONMENTS.codespaces },
                  { label: 'Localhost', value: ENVIRONMENTS.localhost, disabled: isLocalhostDisabled },
                  { label: 'Performance Lab', value: ENVIRONMENTS.perfLab },
                  { label: 'Staging', value: ENVIRONMENTS.staging },
                ]}
                onChange={setQuickSwapEnv}
              />
              <Flex justify="flex-end" mt={8}>
                <Button onClick={onSubmitQuickSwap}>Submit</Button>
              </Flex>
            </Flex>
          </Card>
        </Box>

        <Box mt={8}>
          <Card>
            <Text textStyle="heading-small">Manual Swap</Text>
            <Flex direction="column" mt={4} gap={6}>
              <Input name="rotom" label="API base URL" value={manualSwapEnv.rotom} onChange={handleOnChangeManualEnv} />
              <Input name="auth" label="Auth base URL" value={manualSwapEnv.auth} onChange={handleOnChangeManualEnv} />
              <Input name="ehr" label="EHR base URL" value={manualSwapEnv.ehr} onChange={handleOnChangeManualEnv} />
              <Input
                name="atlas"
                label="Atlas base URL"
                value={manualSwapEnv.atlas}
                onChange={handleOnChangeManualEnv}
              />
            </Flex>

            <Flex justify="flex-end" mt={8} gap={8}>
              <Button colorScheme="negative" onClick={resetEnv}>
                Reset
              </Button>
              <Button onClick={onSubmitManualSwap}>Submit</Button>
            </Flex>
          </Card>
        </Box>
      </Container>
    </>
  );
};

Index.getLayout = (page) => <PublicLayout>{page}</PublicLayout>;

export default Index;
